import React, { ReactElement } from "react"
import { PageProps } from "gatsby"
import SEO from "../../../../components/SEO"
import { ViewApplicationTabProps, ViewApplicationTab } from "./"
import { BasePageContext } from "../../../../interfaces/PageContext"
import T from "@mui/material/Typography"
import DescriptionList from "../../../../components/DescriptionList"
import usePaymentTokens from "../../../../hooks/usePaymentTokens"
import usePaymentConfig from "../../../../hooks/usePaymentConfig"
import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material"
import { PaymentStatus, PaymentToken, Status } from "@ap-frontend/types"
import { formatDateTime } from "@ap-frontend/utility"

const ViewApplicationFeeTab: React.FC<ViewApplicationTabProps> = ({
  profile,
  stageStatuses,
  audits,
}) => {
  const paymentConfig = usePaymentConfig()
  const paymentTokens = usePaymentTokens()

  const applicationFeeTokens =
    (paymentTokens &&
      paymentTokens[
        paymentConfig?.chargeableItems.find(
          item => item.description === "Application Fee"
        )?.amountToPay || ""
      ]) ||
    []

  const usedTokens = applicationFeeTokens.filter(
    token => token.status
  ) as (PaymentToken & { status: PaymentStatus })[]

  const paymentDate = applicationFeeTokens.find(token => token.status?.success)
    ?.status?.dateOfPayment

  const paymentButtonClickedAudits = (audits || []).filter(
    audit =>
      audit.document === "activity" &&
      audit.field === "feePaymentClickedTimestamp"
  )

  const firstPaymentAttempt = paymentButtonClickedAudits.at(-1)
  const lastPaymentAttempt = paymentButtonClickedAudits[0]

  return (
    <>
      <T variant="h2" component="h2">
        Application fee
      </T>
      {stageStatuses.payment === Status.Complete && (
        <Alert severity="success">
          <T>
            Application fee paid on{" "}
            {paymentDate ? formatDateTime(paymentDate.toDate()) : "unknown"}
          </T>
        </Alert>
      )}
      {stageStatuses.payment === Status.NotRequired && (
        <Alert severity="info">
          <T>This applicant is not required to pay an application fee.</T>
        </Alert>
      )}
      {stageStatuses.payment !== undefined &&
        [Status.CannotStart, Status.InProgress, Status.NotStarted].includes(
          stageStatuses.payment
        ) && (
          <Alert severity="warning">
            <T>This applicant needs to pay an application fee.</T>
          </Alert>
        )}
      <T variant="h3" component="h3" sx={{ mb: 2, mt: 4 }}>
        Fee status
      </T>
      <DescriptionList
        md={6}
        lg={5}
        items={[
          ["Overseas", profile.isInternational ? "Yes" : "No"],
          [
            "Exempt country waiver applied",
            !profile.isInternational
              ? "Not applicable"
              : profile.toPayApplicationFee
              ? "No"
              : "Yes",
          ],
          [
            "CAO fee waiver applied",
            !profile.isInternational || !profile.toPayApplicationFee
              ? "Not applicable"
              : profile.waiveApplicationFee
              ? "Yes"
              : "No",
          ],
        ]}
      />
      <T variant="h3" component="h3" sx={{ mb: 2, mt: 4 }}>
        Payment activity
      </T>
      <DescriptionList
        md={6}
        lg={5}
        items={[
          [
            "First attempt",
            firstPaymentAttempt
              ? formatDateTime(firstPaymentAttempt.timestamp.toDate())
              : "-",
          ],
          [
            "Last attempt",
            lastPaymentAttempt
              ? formatDateTime(lastPaymentAttempt.timestamp.toDate())
              : "-",
          ],
          [
            "Total number of attempts",
            (paymentButtonClickedAudits || []).length,
          ],
        ]}
      />
      <T variant="h3" component="h3" sx={{ mb: 2, mt: 4 }}>
        Transaction history
      </T>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">Time and date</TableCell>
            <TableCell component="th">Transaction ID</TableCell>
            <TableCell component="th">Amount</TableCell>
            <TableCell component="th">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usedTokens.map(token => (
            <TableRow key={token.msgId}>
              <TableCell>
                {formatDateTime(token.status.dateOfPayment.toDate())}
              </TableCell>
              <TableCell>{token.status.transId}</TableCell>
              <TableCell>£{token.status.totalPaid}</TableCell>
              <TableCell>
                {token.status.success ? (
                  <T
                    variant="body2"
                    component="span"
                    sx={{ color: "success.dark" }}
                  >
                    Success
                  </T>
                ) : (
                  <T
                    variant="body2"
                    component="span"
                    sx={{ color: "error.dark" }}
                  >
                    Failed
                  </T>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

const ViewApplicationDetailsPage = ({
  params,
  pageContext,
}: PageProps<never, BasePageContext>): ReactElement => {
  const applicationNumber = params.id

  return (
    <>
      <SEO title={`Application #${applicationNumber}`} />
      <ViewApplicationTab
        applicationNumber={applicationNumber}
        TabComponent={ViewApplicationFeeTab}
        questions={pageContext.questions}
      />
    </>
  )
}

export default ViewApplicationDetailsPage
